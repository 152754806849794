import React from 'react';
import { FacilityCardGroup, FacilityCardImage, FacilityCardDesc } from './facility-card.styled';

const FacilityCard = (props) => {
  const data = props;
  return (
    <FacilityCardGroup>
      <FacilityCardImage src={data.image} />
      <FacilityCardDesc>{data.desc}</FacilityCardDesc>
    </FacilityCardGroup>
  );
};

export default FacilityCard;
