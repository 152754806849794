import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

// SCSS
import './statics/scss/app.scss';

// JS
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
