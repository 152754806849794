import styled from 'styled-components';

export const PriceCardImageContainer = styled.div`
    background-image: url(${(props) => (props.background != null ? props.background : null)});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: all 1s;
    width: 100%;
`;

export const PricesCardDesc = styled.div`
    display: block;
    width: 100%;
`;

export const PricesCardGroup = styled.div`
    display: flex;
    flex: 0 0 auto;
    width: 50%;
    text-align: center;

    @media (min-width: 769px) {
        ${PriceCardImageContainer}:hover {
            background-size: 150% 150%;
            transition: all 1s;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        ${PriceCardImageContainer} {
            background-size: cover;
            transition: all 1s;
        }
    }
`;

export const PricesCardTitle = styled.h4`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    color: #ffffff;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
`;

export const PriceCardDetail = styled.div`
    font-family: "NunitoSans-SemiBold", sans-serif !important;
    color: #ffffff;
    text-align: left;
    margin-top: 1.5rem;
`;
