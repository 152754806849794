import styled from 'styled-components';

export const FacilitiesContainer = styled.div`
`;

export const FacilitiesTitle = styled.h2`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    text-align: center;
    color: #000000;
`;

export const FacilitiesGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: start;
`;

export const FacilitiesTip = styled.div`
    margin-top: 60px;
    text-align: center;
`;
