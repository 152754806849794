import React, { useState } from 'react';
import axios from 'axios';
import {
  FormContactGroup,
  FormContactTitle,
  FormContactForm,
  FormContactFormGroup,
  FormContactFormTitle,
  FormContactInput,
  FormContactButton,
} from './formContact.styled';

const FormContact = (props) => {
  const data = props;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    axios.post(`${process.env.REACT_APP_API_URL}/entries`, {
      name,
      phone,
      email,
    }).then((response) => {
      // console.log(response);
    });
  };

  return (
    <FormContactGroup className="container">
      <FormContactTitle>{data.title}</FormContactTitle>
      <FormContactForm onSubmit={handleSubmit} id="spacehub-form">
        <FormContactFormGroup className="form-group">
          <FormContactFormTitle>Name</FormContactFormTitle>
          <FormContactInput type="text" className="form-control" id="name" aria-describedby="name" placeholder="Your name" onChange={(e) => setName(e.target.value)} value={name} required />
        </FormContactFormGroup>
        <FormContactFormGroup className="form-group">
          <FormContactFormTitle>Phone</FormContactFormTitle>
          <FormContactInput type="text" className="form-control" id="phone" aria-describedby="phone" placeholder="Your phone" onChange={(e) => setPhone(e.target.value)} value={phone} required />
        </FormContactFormGroup>
        <FormContactFormGroup className="form-group">
          <FormContactFormTitle>Email</FormContactFormTitle>
          <FormContactInput type="email" className="form-control" id="email" aria-describedby="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} value={email} required />
        </FormContactFormGroup>
        <FormContactButton className="btn btn-primary" type="submit">SUBMIT</FormContactButton>
      </FormContactForm>
    </FormContactGroup>
  );
};

export default FormContact;
