import styled from 'styled-components';

export const FormContactGroup = styled.div`
    margin-top: 5rem;
    margin-bottom: 5rem;
`;

export const FormContactTitle = styled.h3`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
`;

export const FormContactForm = styled.form`
    margin-top: 3rem;
`;

export const FormContactFormGroup = styled.div`
    margin-top: 1rem;
`;

export const FormContactFormTitle = styled.div`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    font-size: 18px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const FormContactInput = styled.input`
    font-size: 18px;
    border: 1px solid #000000;
    margin-top: 1rem;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const FormContactButton = styled.button`
    font-size: 20px;
    padding: .5rem 3rem;
    margin-top: 3rem;
`;
