import React, { useState, useEffect } from 'react';

// component
import { HeaderGroup, HeaderLink } from './header.styled';

import Logo from '../../statics/images/Logo/ok_space hub logo-13 red.png';

const Header = (props) => {
  const data = props;
  const [onScroll, setOnScroll] = useState(false);

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 300) {
      setOnScroll(true);
    } else {
      setOnScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderGroup data-is-scroll={onScroll} className="navbar navbar-expand-sm navbar-light fixed-top">
      {/* <nav className={onScroll ? 'bg-white navbar navbar-expand-sm
      navbar-light fixed-top' : null }> */}
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={process.env.REACT_APP_API_URL + data.data.logo.url} className="object-cover" width="150" height="100" alt="logoThumb" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <HeaderLink data-is-scroll={onScroll} className="nav-link active" aria-current="page" href="#header">HOME</HeaderLink>
            </li>
            <li className="nav-item">
              <HeaderLink data-is-scroll={onScroll} className="nav-link" aria-current="page" href="#facilities">OUR FACILITIES</HeaderLink>
            </li>
            <li className="nav-item">
              <HeaderLink data-is-scroll={onScroll} className="nav-link" aria-current="page" href="#prices">PRICING</HeaderLink>
            </li>
            <li className="nav-item">
              <HeaderLink data-is-scroll={onScroll} className="nav-link" aria-current="page" href="#place">LOCATION</HeaderLink>
            </li>
            <li className="nav-item">
              <HeaderLink data-is-scroll={onScroll} className="nav-link" aria-current="page" href="#contact">CONTACT US</HeaderLink>
            </li>
          </ul>
        </div>
      </div>
      {/* </nav> */}
    </HeaderGroup>
  );
};

export default Header;
