import React from 'react';
import parse from 'html-react-parser';
import { Icon, InlineIcon } from '@iconify/react';
import facebookFilled from '@iconify-icons/ant-design/facebook-filled';
import twitterOutlined from '@iconify-icons/ant-design/twitter-outlined';
import instagramOutlined from '@iconify-icons/ant-design/instagram-outlined';
import youtubeFilled from '@iconify-icons/ant-design/youtube-filled';

import {
  FooterGroup, FooterGroupMenu, FooterLogo, FooterMenuItem, FooterContact,
} from './footer.styled';

// assets
import Logo from '../../statics/images/Logo/ok_space hub logo-13 red.png';

const Footer = (props) => {
  const data = props;
  return (
    <FooterGroup>
      <div className="container d-flex justify-content-between">
        <div className="w-100">
          <FooterGroupMenu>
            <FooterMenuItem href="#header">HOME</FooterMenuItem>
            <FooterMenuItem href="#place">LOCATION</FooterMenuItem>
            <FooterMenuItem href="#facilities">OUR FACILITIES</FooterMenuItem>
            <FooterMenuItem href="#contact">CONTACT US</FooterMenuItem>
            <FooterMenuItem href="#prices">PRICING</FooterMenuItem>
          </FooterGroupMenu>
          <FooterLogo src={process.env.REACT_APP_API_URL + data.data.logo.url} className="img-fluid w-50" alt="logo" />
          <div className="mt-4">{data.data.copyright}</div>
        </div>
        <FooterContact>
          <div style={{ whiteSpace: 'pre-line' }} />
          {parse(data.data.address)}
          <div />
          <div className="mt-4 ">{data.data.phone}</div>
          <button type="button" className="btn btn-link mt-4" onClick={() => window.open(`https://${data.data.website}`, '_blank')}>{data.data.website}</button>
          <div className="mt-4">
            {
              data.data.contacts.map((item) => (
                <>
                  {
                    item.icon === 'instagram' ? (
                      <Icon icon={instagramOutlined} width="34" className="me-3" style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')} />
                    ) : null
                  }
                  {
                    item.icon === 'twitter' ? (
                      <Icon icon={twitterOutlined} width="34" className="me-3" style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')} />
                    ) : null
                  }
                  {
                    item.icon === 'facebook' ? (
                      <Icon icon={facebookFilled} width="34" className="me-3" style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')} />
                    ) : null
                  }
                  {
                    item.icon === 'youtube' ? (
                      <Icon icon={youtubeFilled} width="34" className="me-3" style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')} />
                    ) : null
                  }
                </>
              ))
            }
          </div>
        </FooterContact>
      </div>
    </FooterGroup>
  );
};
export default Footer;
