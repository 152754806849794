import styled from 'styled-components';

export const PlacesGroup = styled.div`
    display: flex;
    align-items: start;
    @media (max-width: 576px) {
        display: block;
    }
`;

export const PlacesMap = styled.div`
    width: 60%;
    @media (max-width: 576px) {
        width: 100%;
    }
`;

export const PlacesMapTitle = styled.h2`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
`;

export const PlacesMapButton = styled.button`
    font-size: 16px;
    padding: .5rem 2rem;
`;

export const PlacesImage = styled.div`
    width: 40%;
    margin: auto;
    @media (max-width: 576px) {
        width: 100%;
        margin-top: 3rem;
    }
`;

export const PlacesImageContent = styled.img`
    width: 100%;
    height: auto;
    border-radius: 10px;
`;
