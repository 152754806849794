import React from 'react';
import {
  PricesCardGroup, PriceCardImageContainer, PricesCardDesc, PricesCardTitle, PriceCardDetail,
} from './prices-card.styled';

const PricesCard = (props) => {
  const data = props;
  return (
    <PricesCardGroup>
      <PriceCardImageContainer background={`${process.env.REACT_APP_API_URL}${data.data.image.url}`} />
      <PricesCardDesc>
        <div className="d-flex flex-column h-100 align-items-start p-5 justify-content-start">
          <PricesCardTitle>
            {data.data.title}
          </PricesCardTitle>
          {
            data.data.capacity !== '' || data.data.price != null || data.data.type != null ? (
              <>
                <div className="text-white mt-3">Spesification</div>
                <PriceCardDetail>
                  {
                    data.data.capacity != null ? (
                      <div>
                        {data.data.capacity}
                      </div>
                    ) : null
                  }
                  {
                    data.data.price != null ? (
                      <div>
                        {data.data.price}
                      </div>
                    ) : null
                  }
                  {
                    data.data.type != null ? (
                      <div>
                        {data.data.type}
                      </div>
                    ) : null
                  }
                </PriceCardDetail>
              </>
            ) : <div className="text-white mt-3">*COMING SOON*</div>
          }
        </div>
      </PricesCardDesc>
    </PricesCardGroup>
  );
};

export default PricesCard;
