import React from 'react';
import parse from 'html-react-parser';
import {
  FacilitiesContainer, FacilitiesTitle, FacilitiesGroup, FacilitiesTip,
} from './facilities.styled';
import FacilityCard from './_components/facility-card';

// assets
import Reception from '../../statics/images/Icon/reception.svg';
import Chair from '../../statics/images/Icon/chair.svg';
import MeetingRoom from '../../statics/images/Icon/meeting-room.svg';
import FreeWifi from '../../statics/images/Icon/free-wifi.svg';
import Coffee from '../../statics/images/Icon/coffee.svg';
import Food from '../../statics/images/Icon/food.svg';

const Facilities = (props) => {
  const data = props;
  return (
    <FacilitiesContainer className="container">
      <FacilitiesTitle>{data.title}</FacilitiesTitle>
      <FacilitiesGroup>
        {
          data.data.map((item) => (
            <FacilityCard key={item.id} image={`${process.env.REACT_APP_API_URL}${item.image?.url}`} desc={item.desc} />
          ))
        }
      </FacilitiesGroup>
      <FacilitiesTip>
        {parse(data.notes)}
      </FacilitiesTip>
    </FacilitiesContainer>
  );
};

export default Facilities;
