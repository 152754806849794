import styled from 'styled-components';

export const SectionGroup = styled.div`
    background-image: url(${(props) => (props.background != null ? props.background : null)});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10rem 0 0 0;
    padding-top: ${(props) => (props.paddingTop != null ? `${props.paddingTop}rem` : null)};
    padding-bottom: ${(props) => (props.paddingBottom != null ? `${props.paddingBottom}rem` : null)};
    @media (max-width: 768px) {
        padding-top: ${(props) => (props.paddingTop != null ? `${props.paddingTop - 5}rem` : null)};
    }

    &[data-is-fullscreen=true] {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 100vh;
        padding: 0;
    }
`;

export const SectionHeading1 = styled.h1`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    text-align: center;
    color: #ffffff;
`;

export const SectionContent = styled.div`
`;
