import React, { useState } from 'react';
import { SectionGroup, SectionHeading1 } from './section.styled';

const Section = (props) => {
  const data = props;
  const fullScreen = data.fullScreen != null;
  return (
    <SectionGroup
      className={data.className}
      background={data.background}
      paddingTop={data.paddingTop}
      paddingBottom={data.paddingBottom}
      id={data.id}
      data-is-fullscreen={fullScreen}
    >
      {
        data.header1 ? (
          <SectionHeading1 className="container">{ data.header1}</SectionHeading1>
        ) : (
          <div>
            <div className="text-center">
              {data.note}
            </div>
            <div className="mt-3">
              {data.children}
            </div>
          </div>
        )
      }
    </SectionGroup>
  );
};

export default Section;
