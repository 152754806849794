import styled from 'styled-components';

export const HeaderGroup = styled.nav`
    transition: .5s;
    &[data-is-scroll="true"]{
        background: #ffffff;
    }
`;

export const HeaderLink = styled.a`
    color: #ffffff !important;
    transition: 1s;
    font-size: 18px;
    padding-left: 2rem !important;
    &[data-is-scroll="true"]{
      color: #000000 !important;
    }
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
