import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

// Components
import Header from './components/header/header';
import Section from './components/section/section';
import Highlight from './components/highlights/highlight';
import Facilities from './components/facilities/facilities';
import Prices from './components/prices/prices';
import Places from './components/places/places';
import Contact from './components/contact/contact';
import FormContact from './components/formContact/formContact';
import Footer from './components/footer/footer';

function App() {
  const [headerData, setHeaderData] = useState(null);
  const [homeData, setHomeData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const [gaID, setGaID] = useState('');
  const [gtmID, setGtmID] = useState('');
  useEffect(() => {
    async function getHeaderData() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/header`)
        .then((response) => {
          setHeaderData(response.data);
        });
    }
    async function getHomeData() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/home`)
        .then((response) => {
          setHomeData(response.data);
        });
    }
    async function getFooterData() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/footer`)
        .then((response) => {
          setFooterData(response.data);
        });
    }
    async function getGAID() {
      axios.get(`${process.env.REACT_APP_API_URL}/header`)
        .then((response) => {
          // setGaID(response.data.googleAnalytics);
          // ReactGA.initialize(response.data.googleAnalytics);
          // ReactGA.send('pageview');
        });
    }
    getHeaderData();
    getHomeData();
    getFooterData();
    getGAID();
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/header`).then((res) => {
      ReactGA.initialize(res.data.googleAnalytics);
      // setGaID(res.data.googleAnalytics);
      setGtmID(res.data.googleTagManager);
    });
  }, []);

  // ReactGA.initialize(gaID);
  ReactGA.send('pageview');
  const tagManagerArgs = {
    gtmId: gtmID,
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <div>
      {
        headerData != null && homeData != null && footerData != null ? (
          <>
            <Helmet>
              <meta name="Description" content={headerData?.metaDescriptions} />
              <meta name="Keyword" content={headerData?.metaKeywords} />
            </Helmet>
            <Header data={headerData} />
            <Section
              background={process.env.REACT_APP_API_URL + homeData.Banner.url}
              id="header"
              fullScreen
              header1={homeData.Description}
            />
            <Highlight data={homeData.highlights} />
            <Section paddingTop="15" id="facilities" note={homeData.FacilitiesSubtitle}>
              <Facilities data={homeData.facilities} title={homeData.FacilitiesTitle} notes={homeData.FacilitiesNotes} />
            </Section>
            <Section id="prices" note={homeData.PriceSubtitle}>
              <Prices data={homeData.prices} title={homeData.PriceTitle} />
            </Section>
            <Section id="place" paddingBottom="10">
              <Places banner={homeData.FindUsBanner} title={homeData.FindUsTitle} url={homeData.FindUsURL} map={homeData.Map} />
            </Section>
            <Contact id="contact" data={homeData.contacts} title={homeData.ContactUsTitle} />
            <FormContact id="formContact" title={homeData.ContactUsFormTitle} />
            <Footer id="footer" data={footerData} />
          </>
        ) : null
      }
    </div>
  );
}

export default App;
