import styled from 'styled-components';
import { PricesCardGroup, PricesCardDesc } from './_components/prices-card.styled';

export const PricesContainer = styled.div`
`;

export const PricesTitle = styled.h2`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    text-align: center;
    color: #000000;
`;

export const PricesGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 5rem;
    
    ${PricesCardGroup}:nth-child(odd) ${PricesCardDesc} {
        background: #000000;
    }
    
    ${PricesCardGroup}:nth-child(even) ${PricesCardDesc} {
        background: #EF4136;
    }
`;
