import styled from 'styled-components';

export const HighlightContainerGroup = styled.div`
    position: absolute;
    top: calc(100vh - 100px);
    width: 100%;
    height: 200px;
    @media (max-width: 768px) {
        top: calc(100vh - 75px);
        height: 150px;
    }
`;

export const HighlightGroup = styled.div`
    background: #EF4136;
    padding: 3rem;
    flex: 1;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    @media (max-width: 768px) {
        padding: 1rem;
    }
    @media (max-width: 576px) {
        margin: 1rem;
    }
`;

export const HighlightCard = styled.h4`
    flex: 1;
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    color: #ffffff;
`;
