import styled from 'styled-components';

export const FooterGroup = styled.div`
    background: #000000;
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: #FFFFFF;
`;

export const FooterGroupMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    
    @media (max-width: 576px) {
        display: block;
        width: 100%
    }
`;

export const FooterLogo = styled.img`
    width: 250px;
    height: 100px;
    object-fit: cover;
    
    @media (max-width: 576px) {
        width: 50px;
        height: 50px;
    }
`;

export const FooterMenuItem = styled.a`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    font-size: 18px;
    width: 50%;
    text-decoration: none;
    color: #FFFFFF;
    transition: all .5s;
    margin-bottom: 1rem;

    &:hover {
        color:  #E82727;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
    
    @media (max-width: 576px) {
        display: block;
        width: 100%
    }
`;

export const FooterContact = styled.div`
    font-family: "NunitoSans-SemiBold", sans-serif !important;
    font-size: 18px;
    width: 100%;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
