import styled from 'styled-components';

export const FacilityCardGroup = styled.div`
    width: calc(100% / 3);
    padding: 0 5rem;
    margin-top: 5rem;
    text-align: center;
    @media (max-width: 768px) {
        padding: 0;
    }
    @media (max-width: 578px) {
        width: calc(100% / 2);
    }
`;

export const FacilityCardImage = styled.img`
    display: grid;
    align-items: center;
    width: 100px;
    height: auto;
    margin: auto;
`;

export const FacilityCardDesc = styled.h4`
    color: #000000;
    margin-top: 2rem;
    font-weight: 700;
    width: 100%;
    min-height: 50px;
    display: grid;
    align-items: center;
`;
