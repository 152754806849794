import React from 'react';
import { HighlightContainerGroup, HighlightGroup, HighlightCard } from './highlight.styled';

const Highlight = (props) => {
  const data = props;
  return (
    <HighlightContainerGroup>
      <div className="container h-100">
        <HighlightGroup className="row">
          {
            data.data?.slice(0, 3).map((item) => (
              <HighlightCard key={item.id} className="col-4">{item.title}</HighlightCard>
            ))
          }
        </HighlightGroup>
      </div>
    </HighlightContainerGroup>
  );
};

export default Highlight;
