import React from 'react';
import {
  PlacesGroup, PlacesMap, PlacesMapTitle, PlacesMapButton, PlacesImage, PlacesImageContent,
} from './places.styled';

const Places = (props) => {
  const data = props;
  return (
    <PlacesGroup className="container">
      <PlacesMap>
        <PlacesMapTitle className="font-weight-bold">{data.title}</PlacesMapTitle>
        <PlacesMapButton className="btn btn-primary mt-3" type="button" onClick={() => window.open(data.url, '_blank')}>OPEN GOOGLE MAPS</PlacesMapButton>
        <img src={process.env.REACT_APP_API_URL + data.map.url} alt="maps" className="img-fluid" />
      </PlacesMap>
      <PlacesImage>
        <PlacesImageContent src={process.env.REACT_APP_API_URL + data.banner.url} />
      </PlacesImage>
    </PlacesGroup>
  );
};

export default Places;
