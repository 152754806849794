import styled from 'styled-components';

export const ContactGroup = styled.div`
    background-image: url(${(props) => (props.background != null ? props.background : null)});
    width: 100%;
    height: 450px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ContactTitle = styled.h3`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    color: #FFFFFF;
    text-align: center;
`;

export const ContactDetailGroup = styled.div`
    margin-top: 3rem;
    display: flex;
    width: 100%;

    @media (max-width: 576px) {
        display: block;
    }
`;

export const ContactDetailItem = styled.div`
    font-family: "NunitoSans-ExtraBold", sans-serif !important;
    width: calc(100%/3);
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 576px) {
        font-size: 14px;
        width: 100%;
        padding: 1.5rem 0;
    }
`;
