import React from 'react';
import { PricesContainer, PricesTitle, PricesGroup } from './prices.styled';
import PricesCard from './_components/prices-card';

const Prices = (props) => {
  const data = props;
  return (
    <PricesContainer>
      <PricesTitle>{data.title}</PricesTitle>
      <PricesGroup>
        {
          data.data.map((item) => (
            <PricesCard key={item.id} data={item} />
          ))
        }
      </PricesGroup>
    </PricesContainer>
  );
};

export default Prices;
