import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';
import emailIcon from '@iconify-icons/mdi/email';
import instagramIcon from '@iconify-icons/mdi/instagram';
import whatsappIcon from '@iconify-icons/mdi/whatsapp';
import {
  ContactGroup, ContactTitle, ContactDetailGroup, ContactDetailItem,
} from './contact.styled';

// assets
import Image from '../../statics/images/photo/background-contact.jpg';

const Contact = (props) => {
  const data = props;
  return (
    <ContactGroup background={Image} id={data.id}>
      <div className="container">
        <ContactTitle>{data.title}</ContactTitle>
        <ContactDetailGroup>
          {data.data.map((item) => (
            <>
              {
                item.icon === 'whatsapp' ? (
                  <ContactDetailItem style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')}>
                    <Icon icon={whatsappIcon} width="34" className="d-inline-block me-1" />
                    {item.account}
                  </ContactDetailItem>
                ) : null
              }
              {
                item.icon === 'email' ? (
                  <ContactDetailItem style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')}>
                    <Icon icon={emailIcon} width="34" className="d-inline-block me-1" />
                    {item.account}
                  </ContactDetailItem>
                ) : null
              }
              {
                item.icon === 'instagram' ? (
                  <ContactDetailItem style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, '_blank')}>
                    <Icon icon={instagramIcon} width="34" className="d-inline-block me-1" />
                    {item.account}
                  </ContactDetailItem>
                ) : null
              }
            </>
          ))}
        </ContactDetailGroup>
      </div>
    </ContactGroup>
  );
};

export default Contact;
